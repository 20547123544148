import React from "react";

import AuthButtonGroup from "../common/ButtonGroups/Auth";
import BaseModal, { IBaseModalProps } from "./BaseModal";

const AuthModal: React.FunctionComponent<IBaseModalProps> = ({...props}) => {
  return (
    <BaseModal title={'Vote for your favorite creators'} {...props}>
      <h2 className="mx-auto my-4">
        Join us!
      </h2>
      <AuthButtonGroup />
    </BaseModal>
  );
};

export default AuthModal;
