import React from "react";

import { useLogout } from "../../../hooks/auth/useLogout";
import PrimaryButton from "./Primary";

const LogoutButton: React.FunctionComponent = () => {
  const { onLogout, isLoading } = useLogout();

  const handleClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    onLogout();
  };

  return (
    <PrimaryButton
      onClick={handleClick}
      className="px-3 py-2"
      disabled={isLoading}
    >
      {" "}
      {isLoading ? "loading" : "Sign Out"}
    </PrimaryButton>
  );
};

export default LogoutButton;
