import React, { ReactElement, useEffect, useState } from "react";

import Modal from "react-modal";

interface IToastProps extends Modal.Props {
  isOpen: boolean;
  interval?: number;
  message?: string;
  image?: ReactElement;
  imageClassName?: string;
  onClose?: () => void;
}

export type IBaseToastProps = IToastProps;

const DEFAULT_INTERVAL = 3 * 1000;

const BaseToast: React.FunctionComponent<IBaseToastProps> = ({
  isOpen,
  interval = DEFAULT_INTERVAL,
  message,
  onClose = () => {},
  children,
  className,
  image,
  imageClassName,
  ...props
}) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(isOpen);
  }, [isOpen]);

  useEffect(() => {
    setTimeout(() => {
      if (isActive) setIsActive(false);
      onClose();
    }, interval);
  }, [isActive]);

  return (
    <Modal
      id="Toast"
      className={`bg-secondary-500 flex flex-col justify-center items-center rounded-xl p-4 mx-auto max-h-60 inset-x-10 sm:inset-x-1/3 absolute top-1/2 transform -translate-y-1/2 focus:outline-none focus:ring-0 shadow-xl ${className}`}
      isOpen={isActive}
      {...props}
    >
      {image && <div className={imageClassName}>{image}</div>}
      {children}

      {message && <h1 className="text-white text-center px-4">{message}</h1>}
    </Modal>
  );
};

export default BaseToast;
