import React from "react";

import VoteIcon from "components/Icons/VoteIcon";

import BaseToast, { IBaseToastProps } from "./BaseToast";

const VotedToast: React.FC<Omit<IBaseToastProps, "message">> = ({
  ...props
}) => {
  return (
    <BaseToast
      className="animate-vanish py-40 px-0"
      message={"Thank you for your vote"}
      {...props}
    >
      <div className="w-14 h-14 mb-6">
        <VoteIcon className="fill-shades-0"/>
      </div>
    </BaseToast>
  );
};

export default VotedToast;
