import React, { useState } from "react";

import { UsersIcon } from "@heroicons/react/solid";
import BaseButton from "components/common/Buttons/BaseButton";
import { CreatoIcon } from "components/Icons/CreatoIcon";
import { FaPen } from "react-icons/fa";

import BaseInputV2, { IBaseInputProps } from "../common/Inputs/BaseInputV2";

interface IDareOptionProps extends Omit<IBaseInputProps, 'onClick'> {
  label: string;
  vote?: number;
  voter?: number;
  username: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

const DefaultDare: React.FunctionComponent<IDareOptionProps> = ({
  name,
  id,
  label,
  vote,
  voter,
  username,
  className,
  children,
  onClick,
  ...props
}) => {
  const [hovered, setHovered] = useState(false);

  const onMouseOver: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    setHovered(true);
  }

  const onMouseOut: React.MouseEventHandler<HTMLButtonElement> = () => {
    setHovered(false);
  }

  return (
    <>
      <BaseButton
        className={`w-full flex justify-between items-center py-6 px-8 bg-neutral-50 text-neutral-800 hover:bg-primary-300 hover:text-slate-50 disabled:bg-neutral-100 disabled:text-neutral-100 ${className}`}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        onClick={onClick}
      >
        {label}
        {
          (children && React.Children.map(children, (child, i) => (
            React.cloneElement(
              child as JSX.Element, 
              {
                className: `ml-2 h-5 w-5 fill-primary-500 ${hovered ? "fill-slate-50" : ""}`,
                ariaHidden: true
              }
            )
          ))) || 
          <FaPen className={`fill-primary-500 ${hovered ? "fill-slate-50" : ""}`} aria-hidden="true" />
        }
      </BaseButton>
      <BaseInputV2 type="hidden" name={name} id={id}/>
      <div className="mt-2 flex items-center justify-between text-base font-medium text-gray-900">
        <div className="flex">
          {vote ? <span className="inline-flex gap-x-2">
            <CreatoIcon className="ml-2 h-5 w-5 fill-neutral-800" color="" aria-hidden="true"/>
            {vote || 0}
          </span>: null}
          {voter ? <span className="inline-flex gap-x-2">
            <UsersIcon className="ml-2 h-5 w-5 text-neutral-800" aria-hidden="true"/>
            {voter || 0}
          </span> : null}
        </div>
        <label htmlFor={name} className="flex justify-end text-neutral-800">
          by @{username}
        </label>
      </div>
    </>
  );
};


const Dare = {
  Default: DefaultDare,
  Leading: null
}


export default Dare

