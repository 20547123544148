import React, { useCallback } from "react";

import { navigate } from "gatsby-link";

import PrimaryButton from "../common/Buttons/Primary";
import BaseModal, { IBaseModalProps } from "./BaseModal";

const DEFAULT_URL = 'https://buy.stripe.com/dR6dS2cU9dZYexq000';

const CoinsModal: React.FunctionComponent<IBaseModalProps> = ({
  ...props
}) => {
  const onButtonClick = useCallback(() => {
    navigate(DEFAULT_URL)
  }, []);

  return (
    <BaseModal title={'One More Step !'} {...props}>
      <div className="flex flex-col content-between space-y-10 h-full w-full items-center mt-10 ">
        <h2 className="text-sm font-black">
          You have insufficient Creato Coins.
        </h2>

        <PrimaryButton disabled={false} className="text-sm" value="Top Up Now" onClick={onButtonClick}>
          {/* <a href={DEFAULT_URL}>Top Up Now</a> */}
        </PrimaryButton>
      </div>
    </BaseModal>
  );
};

export default CoinsModal;
