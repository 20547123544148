import React from "react";

import PrimaryButton, { IBaseButtonProps } from "../common/Buttons/Primary";

function CloseButton({
  ...props
}: IBaseButtonProps) {
  return (
    <PrimaryButton
      className={"p-4 rounded-full bg-transparent shadow-none float-right "}
      type="button"
      {...props}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5 text-disabled"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
          clip-rule="evenodd"
        />
      </svg>
    </PrimaryButton>
  );
}

export default CloseButton;
