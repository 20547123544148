import { useCallback, useEffect, useMemo, useState } from "react";

import dayjs from "dayjs";
import { db } from "utils/firebase/configs";

import "dayjs/plugin/utc";
import { VoteReq, vote } from "api/poll";

var utc = require("dayjs/plugin/utc")
dayjs.extend(utc)

export interface VoteHookInterface {
  onCompleted?: (data: ReturnType<typeof vote>) => void;
}

const useVote = (props?: VoteHookInterface) => {
  const [loading, setLoading] = useState(false);

  const onCompleted = useCallback((data) => {
    props?.onCompleted && props.onCompleted(data);
  }, [props])

  const onVote = useCallback(async (data: VoteReq) => {
    try {
      setLoading(true);
      const response = await vote(data);

      onCompleted(response);

      return response;
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [onCompleted])


  return useMemo(
    () => ({
      loading,
      vote: onVote
    }),
    [loading, onVote]
  );
};

export default useVote;
