import React, { ReactElement } from "react";

import Modal from "react-modal";

import CloseButton from "./CloseButton";

interface IModalProps extends Modal.Props {
  onClose?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  title?: string;
  subTitle?: string;
  icon?: ReactElement;
  iconClassName?: string;
  isOpen: boolean;
}

export type IBaseModalProps = IModalProps;

const BaseModal: React.FunctionComponent<IBaseModalProps> = ({
  onClose,
  title,
  children,
  className,
  portalClassName,
  icon,
  subTitle,
  iconClassName,

  ...props
}) => {
  // console.log("base", isOpen);
  return (
    <Modal
      id="Modal"
      ariaHideApp={false}
      className={`bg-white border-primary  justify-center items-center gap-x-2 rounded-3xl shadow-xl border p-4 mx-auto inset-x-4 sm:inset-x-1/4 absolute top-1/2 transform -translate-y-1/2 focus:outline-none focus:ring-0 z-50 ${className}`}
      portalClassName={`z-10 ${portalClassName}`}
      {...props}
    >
      <div
        className={`w-full h-auto p-1 flex items-center ${
          title
            ? onClose
              ? "justify-between"
              : "justify-center"
            : "justify-end"
        }`}
      >
        {title && <h3 className="text-center">{title}</h3>}
        {onClose && <CloseButton onClick={onClose} />}
      </div>
      {icon && (
        <div
          className={`flex items-center justify-center text-5xl mt-4 text-primary-500 ${iconClassName}`}
        >
          {icon}
        </div>
      )}
      <h3 className="text-center mt-2">{subTitle}</h3>

      <p className="Modal_Content flex flex-col justify-center items-center ">
        {children}
      </p>
    </Modal>
  );
};

export default BaseModal;
