import React, { useContext } from "react";

import { isLoggedIn } from "utils/auth";

import { IBaseButtonProps } from "../Buttons/BaseButton";
import LoginButton from "../Buttons/Login";
import LogoutButton from "../Buttons/Logout";

const AuthButtonGroup: React.FunctionComponent<IBaseButtonProps> = ({ ...props }) =>  {

  return (
    <>
      {/* {process.env.NODE_ENV === 'development' && <RegisterButton />} */}
      {isLoggedIn() ? (
        <LogoutButton {...props}/>
      ) : (
        <LoginButton {...props}/>
      )}
    </>
  );
}

export default AuthButtonGroup;
