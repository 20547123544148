import { useEffect, useMemo, useReducer } from "react";

import { firestore } from "firebase-admin";

interface IFirebaseQuery {
  route: firestore.DocumentData;
}

interface IInitialState {
  status: string;
  data: any;
  error: string | null;
}

type QUERY_IDLE_ACTION = {
  type: typeof QUERY_IDLE_TYPE;
};

type QUERY_SUCCESS_ACTION = {
  type: typeof QUERY_SUCCESS_TYPE;
  payload: any;
};

type QUERY_LOADING_ACTION = {
  type: typeof QUERY_LOADING_TYPE;
};

type QUERY_DOC_ERROR_ACTION = {
  type: typeof QUERY_DOC_ERROR_TYPE;
  payload: string;
};

type QUERY_COLLECTION_ERROR_ACTION = {
  type: typeof QUERY_COLLECTION_ERROR_TYPE;
  payload: string;
};

export const QUERY_IDLE_TYPE = "QUERY:IDLE";
export const QUERY_SUCCESS_TYPE = "QUERY:SUCCESS";
export const QUERY_LOADING_TYPE = "QUERY:LOADING";
export const QUERY_DOC_ERROR_TYPE = "QUERY:DOC:ERROR";
export const QUERY_COLLECTION_ERROR_TYPE = "QUERY:COLLECTION:ERROR";

export type IQueryAction =
  | QUERY_IDLE_ACTION
  | QUERY_SUCCESS_ACTION
  | QUERY_LOADING_ACTION
  | QUERY_DOC_ERROR_ACTION
  | QUERY_COLLECTION_ERROR_ACTION;

function useFirebaseQuery(props: IFirebaseQuery) {
  const { route } = props;

  const promises: FirebaseFirestore.DocumentData[] = [];

  const initialState: IInitialState = {
    status: route ? "loading" : "idle",
    data: [],
    error: null,
  };

  const reducer = (
    state: IInitialState = initialState,
    action: IQueryAction
  ) => {
    switch (action.type) {
      case QUERY_IDLE_TYPE:
        return { status: "idle", data: null, error: null };
      case QUERY_LOADING_TYPE:
        return { status: "loading", data: null, error: null };
      case QUERY_SUCCESS_TYPE:
        return { status: "success", data: action.payload, error: null };
      case QUERY_DOC_ERROR_TYPE:
        return { status: "document error", data: null, error: action.payload };
      case QUERY_COLLECTION_ERROR_TYPE:
        return {
          status: "collection error",
          data: null,
          error: action.payload,
        };
      default:
        return { status: "error", data: null, error: null };
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!route) {
      dispatch({ type: QUERY_IDLE_TYPE });
    }

    dispatch({ type: QUERY_LOADING_TYPE });
    
    const unsubscribe = route.onSnapshot(
      (snap: FirebaseFirestore.QueryDocumentSnapshot | FirebaseFirestore.QueryDocumentSnapshot[]) => {
        try {
          if (snap instanceof Array) {
            snap?.forEach((doc) => {
              promises.push(doc.data());
              Promise.all(promises).then((value) => {
                dispatch({ type: QUERY_SUCCESS_TYPE, payload: value });
              });
            });
          } else {
            const data = snap.data();

            if (data) {
              dispatch({ type: QUERY_SUCCESS_TYPE, payload: data });
            }
          }
        } catch (error: any) {
          dispatch({
            type: QUERY_DOC_ERROR_TYPE,
            payload: `Doc : ${error.message}`,
          });
        }
      }
    );
    return () => {
      unsubscribe();
    };

    // if (singleData === true) {
    //   const unsubscribe = route.onSnapshot(
    //     (snap: FirebaseFirestore.QueryDocumentSnapshot) => {
    //       try {
    //         const data = snap.data();
    //         if (data) {
    //           dispatch({ type: QUERY_SUCCESS_TYPE, payload: data });
    //         }
    //       } catch (error: any) {
    //         dispatch({
    //           type: QUERY_DOC_ERROR_TYPE,
    //           payload: `Doc : ${error.message}`,
    //         });
    //       }
    //     }
    //   );
    //   return () => {
    //     unsubscribe();
    //   };
    // } else {
    //   const unsubscribe = route.onSnapshot(
    //     (snap: FirebaseFirestore.QueryDocumentSnapshot[]) => {
    //       try {
    //         snap!.forEach((doc) => {
    //           promises.push(doc.data());
    //           Promise.all(promises).then((value) => {
    //             dispatch({ type: QUERY_SUCCESS_TYPE, payload: value });
    //           });
    //         });
    //       } catch (error: any) {
    //         dispatch({
    //           type: QUERY_COLLECTION_ERROR_TYPE,
    //           payload: `Collection : ${error.message}`,
    //         });
    //       }
    //     }
    //   );
    //   return () => {
    //     unsubscribe();
    //   };
    // }
  }, []);

  return useMemo(
    () => ({
      ...state
    }),
    [state]
  );
}

export default useFirebaseQuery;
