import TIER_NAME_MAP from "constants/tiers";

import React, { useCallback, useEffect, useState } from "react";

import { RadioGroup } from "@headlessui/react";
import { PollResponse } from "api/poll";
import { Modal } from "components/Modals";
import AuthModal from "components/Modals/Auth";
import CoinsModal from "components/Modals/Coins";
import Dare from "components/Options/Dare";
import TopSection from "components/Sections/Top";
import BaseToast from "components/Toasts/BaseToast";
import VotedToast from "components/Toasts/Voted";
import dayjs from "dayjs";
import { PageProps } from "gatsby";
import { classNames } from "helper/style";
import useOptionUpdater from "hooks/options/useOptionUpdater";
import { BalanceTierMap, TierKeys, VoteTierMap } from "types/api";

import "dayjs/plugin/utc";
import { isLoggedIn } from "utils/auth";
import useGlobalContext from "hooks/common/useGlobalContext";
import useVote from "hooks/polls/useVote";
import useLoading from "hooks/common/useLoading";
import produce from "immer";

interface IProps extends PageProps<any, PollResponse, any> {
  params: {
    id: string;
    optionId: string;
  };
}

var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const BetPage: React.FunctionComponent<IProps> = ({
  params,
  ...props
}) => {
  const { id, optionId } = params;
  const { option } = useOptionUpdater({
    id: optionId,
  });
  const { globalState } = useGlobalContext();
  const { isOpen: loading, setLoading } = useLoading();
  const [selected, setSelected] = useState<TierKeys | undefined>();
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [isCoinModalOpen, setIsCoinModalOpen] = useState(false);
  const [isVoteModalOpen, setIsVoteModalOpen] = useState(false);
  const [isVoted, setIsVoted] = useState(false);
  const [error, setError] = useState<{
    value: boolean,
    message: string | null
  }>({
    value: false,
    message: null
  })
  const { vote } = useVote();

  const onVote: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    try {
      setLoading(true);
      await vote!({
        pollId: id,
        optionId,
        tier: selected!,
      });
      setIsVoted(true);
    } catch {
      setError({
        value: true,
        message: "Oops! Only vote for the same choice again :)"
      })
    } finally {
      setLoading(false);
    }
  }

  const reset: React.MouseEventHandler<HTMLButtonElement> = useCallback((e) => {
    setIsAuthModalOpen(false);
    setIsCoinModalOpen(false);
    setIsVoteModalOpen(false);
    setSelected(undefined);
  }, []);

  useEffect(() => {
    if (!isLoggedIn()) {
      return setIsAuthModalOpen(true);
    }

    const payout =
          VoteTierMap[selected as TierKeys] *
          BalanceTierMap[selected as TierKeys];

    if (globalState.user.balance && globalState.user.balance < payout) {
      return setIsCoinModalOpen(true);
    }

    if (selected) {
      setIsVoteModalOpen(true);
    }

  }, [selected])

  useEffect(() => {
    if (error.value) {
      setIsVoted(false);
      setIsVoteModalOpen(false);
    }
  }, [error])

  console.log(`[poll/detail/[id]/bet/[option]]: ${optionId}`);
  if (!option) return null;
  return (
    <>
      <AuthModal isOpen={isAuthModalOpen} onClose={reset} />

      <CoinsModal isOpen={isCoinModalOpen} onClose={reset} />

      <Modal.Action 
        open={isVoteModalOpen} 
        onClose={() => {
          setIsVoteModalOpen(false);
        }}
        title={selected ? TIER_NAME_MAP[selected] : ""}
        value="confirm"
        onClick={onVote}
        disabled={loading}
      >
        <p className="text-sm text-gray-500">
          {`It costs ${TIER_NAME_MAP[selected!]} Donuts for ${option.title}`}
        </p> 
      </Modal.Action>

      <VotedToast isOpen={isVoted} />

      <BaseToast
          isOpen={error.value}
          message={
            error.message || "Oops! Please contact our customer service."
          }
          onClose={() => {
            setError(
              produce(error, (draft) => {
                draft.value = false;
                draft.message = null;
              })
            );
          }}
        />

      <TopSection
        title={"Raise Bet"}
        leftComponent={true}
      />
      
      <div className="max-w-2xl mx-auto px-4 py-4 sm:px-6 lg:max-w-7xl lg:px-8 lg:py-8 lg:mb-9 lg:rounded-lg lg:shadow-lg">
        <Dare.Default
          id={option?.id}
          name={option?.id}
          value={option?.id}
          username={option!.creator}
          label={option!.title}
          className="mt-1 border-none"
        >
          <></>
        </Dare.Default>
        <RadioGroup value={selected} onChange={setSelected}>
          <RadioGroup.Label className="sr-only">dare</RadioGroup.Label>
          <div className="space-y-4">
            {option?.tier?.map((tier: TierKeys) => (
              <RadioGroup.Option
                as="button"
                key={tier}
                value={tier}
                className={({ active, checked }) =>
                  classNames(
                    'w-full cursor-pointer focus:outline-none',
                    active ? 'ring-2 ring-offset-2 ring-primary-500' : '',
                    checked
                      ? 'bg-primary-600 border-transparent text-white hover:bg-primary-700'
                      : 'bg-white border-primary-200 text-gray-900 hover:bg-primary-50',
                    'border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1'
                  )
                }
                disabled={loading}
              >
              <RadioGroup.Label as="p">{TIER_NAME_MAP[tier]}</RadioGroup.Label>
            </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
    </>
  );
};

export default BetPage;
