import { useCallback, useEffect, useMemo, useState } from "react";

import { OptionResponse, getOption } from "api/option";
import dayjs from "dayjs";
import useFirebaseQuery from "hooks/common/useFirebaseQuery";
import { ID } from "types/api";
import { db } from "utils/firebase/configs";

import useLoading from "../common/useLoading";

import "dayjs/plugin/utc";



var utc = require("dayjs/plugin/utc")
dayjs.extend(utc)

export interface OptionUpdaterHookInterface {
  id: ID;
  state?: OptionResponse;
  onCompleted?: (data: OptionResponse) => void;
}

const useOptionUpdater = (props: OptionUpdaterHookInterface) => {
  const { id, state, onCompleted = () => {} } = props;
  const [option, setOption] = useState(state);
  const { isOpen, setLoading } = useLoading(true);
  const { data, status } = useFirebaseQuery({ route: db.collection("options").doc(id) });

  useEffect(() => {      
    if (data) {
      setOption(data)
    }
  }, [data]);

  useEffect(() => {
    if (status !== 'loading') {
      setLoading(false)
    }
  }, [status])

  return useMemo(
    () => ({
      option,
      loading: isOpen
    }),

    [option, isOpen]
  );
};

export default useOptionUpdater;
